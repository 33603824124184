<template>
  <div>
    <AppCard
      class="code-page__panel"
      body-class="flex column q-pa-lg"
      header-color="bg-grey-11"
      :title="$t('label.code')"
      bordered
    >
      <template
        v-if="showCloseBtn"
        #titleRight
      >
        <q-icon
          v-close-popup
          name="close"
          color="grey-2"
          size="24px"
          class="close-icon cursor-pointer"
        />
      </template>
      <div class="code-block">
        <div
          class="code"
          @click="selectCode"
        >
          {{ code }}
        </div>
        <div class="buttons">
          <AppButtonLink
            :label="$t('button.copy')"
            icon-right="fl:duplicate"
            class="text-15-400"
            @click="copyCode(code)"
          />
          <AppButtonLink
            :label="$t('button.sendToDevelopers')"
            icon-right="fl:vector-121"
            class="text-15-400"
            @click="showShareDialog = true"
          />
        </div>
      </div>

      <AppModal
        :is-open="showShareDialog"
        :title="$t('title.shareCode')"
        content-class="share-code-dialog"
        no-maximized
        show-close-btn
        @close="onClose"
      >
        <div
          v-if="isSend"
          class="sent-block"
        >
          <div>
            <q-img
              src="~/assets/check_ring.svg"
              width="82px"
            />
          </div>
          <div class="sent-block-message">
            <div
              class="text-24-700"
              v-html="$t('text.messageSentSuccessfully')"
            />
            <div>
              <div>{{ $t('text.codeSentTo') }}</div>
              <a
                :href="`mailto:${model.email}`"
                class="text-primary"
              >
                {{ model.email }}
              </a>
            </div>
          </div>
        </div>
        <q-form
          v-else
          ref="form"
          greedy
        >
          <div class="form-builder__option form-builder__option_horizontal">
            <AppInput
              v-model="model.email"
              :rules="[rules.REQUIRED, rules.EMAIL]"
              :label="$t('label.emailAddress')"
              type="email"
            />
          </div>
          <div class="form-builder__option form-builder__option_horizontal">
            <AppToggle
              v-model="hasMessage"
              :label="$t('label.addPersonalMessage')"
              left-label
            />
            <AppInput
              v-if="hasMessage"
              v-model="model.message"
              :rules="[rules.REQUIRED]"
              :label="$t('label.messageRecipient')"
              :hint="$t('label.messageSentInEmail')"
              type="textarea"
            />
          </div>
          <div class="form-builder__option buttons">
            <AppButton
              v-close-popup
              :label="$t('button.cancel')"
              color="white"
              text-color="black"
              outline
            />
            <AppButton
              :label="$t('button.send')"
              :loading="loading"
              class="col"
              icon-right="fl:vector-121"
              color="primary"
              text-color="black"
              @click="sendCode"
            />
          </div>
        </q-form>
      </AppModal>
    </AppCard>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppInput from 'common/components/AppInput';
import AppModal from 'common/components/AppModal';
import AppToggle from 'common/components/AppToggle';
import AppButton from 'common/components/buttons/AppButton';
import AppButtonLink from 'common/components/buttons/AppButtonLink';
import handleError from 'common/helpers/handleError';
import rules from 'common/helpers/rules';
import { copyCodeMixin } from 'common/mixins';
import formCode from 'src/helpers/formCode';

export default {
  name: 'CodePage',
  components: {
    AppCard,
    AppButton,
    AppToggle,
    AppInput,
    AppModal,
    AppButtonLink,
  },
  mixins: [copyCodeMixin],
  props: {
    showCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showShareDialog: false,
      isSend: false,
      hasMessage: true,
      loading: false,
      model: {
        code: undefined,
        email: undefined,
        message: undefined,
      },
    };
  },
  computed: {
    rules: () => rules,
    guid() {
      return this.$route.params.guid;
    },
    code() {
      return formCode(this.guid);
    },
  },
  methods: {
    async sendCode() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) {
          return;
        }

        this.loading = true;
        this.model.code = btoa(this.code);
        await this.$axios.post(`forms/${this.guid}/send-code`, this.model);
        this.isSend = true;
      } catch (error) {
        handleError(error);
      } finally {
        this.loading = false;
      }
    },
    onClose() {
      this.showShareDialog = false;
      this.isSend = false;
    },
    selectCode(event) {
      let range;
      if (document.selection) { // IE
        range = document.body.createTextRange();
        range.moveToElementText(event.target);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(event.target);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.code-block {
  word-break: break-all;
  border: 1px solid $grey-2;
  border-radius: $radius-12;

  .code {
    border-bottom: 1px solid $grey-2;
    padding: 25px 20px 20px 25px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }
}
</style>

<style lang="scss">
.share-code-dialog {
  &.modal-content {
    width: 480px;

    .modal-header {
      background-color: $grey-6;
    }

    .modal-body {
      padding: 25px 20px;
    }
  }

  .sent-block {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
    padding: 80px 82px 95px 82px;

    &-message {
      display: flex;
      flex-direction: column;
      gap: 15px;

      a {
        text-decoration: none;
      }
    }
  }

  .buttons {
    display: flex;
    gap: 20px;

    button {
      &:nth-child(1) {
        flex: 1;
      }

      &:nth-child(2) {
        flex: 2;

        .q-btn__content {
          justify-content: space-between;
        }
      }
    }
  }
}
</style>
